import React, { Component } from 'react';

const socials = [
	{
		url: "https://social.coukaratcha.fr/@couka",
		label: "@couka@social.coukaratcha.fr",
		img: {
			alt: "Logo Mastodon",
			filename: "mastodon.svg"
		}
	},
	{
		url: "https://blog.coukaratcha.fr",
		label: "blog.coukaratcha.fr",
		img: {
			alt: "Plume",
			filename: "blog.svg"
		}
	},
	{
		url: "mailto:contact@mickaelgillot.xyz",
		label: "contact@mickaelgillot.xyz",
		img: {
			alt: "Enveloppe",
			filename: "mail.svg"
		}
	}
]


class About extends Component {
	render() {
		return (
			<main id='about'>
				<div className='container'>
					<p id='description'>
						Je suis <strong>Mickaël&nbsp;Gillot</strong>. <br />
						Je suis Ingénieur DevOps et passionné par plein de choses. Vous pourrez trouver ici un aperçu de mon travail, mes projets, mes hobbies ainsi que des liens vers mes réseaux sociaux.
					</p>
					<div id='socials'>
						<h4>Vous pouvez me retrouver ici</h4>
						<SocialList />
					</div>
				</div>
			</main>
		)
	}
}

class SocialList extends Component {
	render() {
		return (
			<ul>
				{
					socials.map(x => (
							<li key={x.url}><img alt={x.img.alt} className="social-img" src={x.img.filename}/><Social url={x.url} label={x.label}/></li>
						)
					)
				}
			</ul>
		)
	}
}

class Social extends Component {
	render() {
		return (
			<a href={this.props.url} rel="me">{this.props.label}</a>
		)
	}
}

export default About;