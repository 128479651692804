import React, { Component } from 'react';

class Project extends Component {
	render() {
		return (
			<article className='project-item'>
				<div className='tags'>
				{
					this.props.tags
						.map(t => <span key={t} className='project-tag'>{`#${t}`}</span>)
				}
				</div>
				<h1>{this.props.title}</h1>
				<p dangerouslySetInnerHTML={{__html: this.props.description}}></p>
				<div className='project-links-list'>
					{
						this.props.links
							.map(l => <ProjectLink 
									key={l.title}
									title={l.title}
									href={l.href}
								/>)
					}
				</div>
			</article>
		)
	}
}

class ProjectLink extends Component {
	render() {
		return (
			<span className='project-link'>
				<a href={this.props.href}>{this.props.title}</a>
			</span>
		)
	}
}

class ProjectsList extends Component {
	render() {
		return (
			<section aria-labelledby='projects-title' id='projects'>
				<div className='container'>
					<div className='projects-list'>
						<Project 
							title='Paradoxe de Monty-Hall'
							description="Une application vulgarisant le paradoxe de Monty-Hall et illustrant par des statistiques colaboratives les propbabilités d'échecs et de réussites en fonction des choix pris."
							tags={[
								'Mathématiques',
								'Pédagogie',
								'Web'
							]}
							links={[
								{
									title: 'Site web',
									href: 'https://monty-hall.coukaratcha.fr'
								},
								{
									title: 'Wikipédia',
									href: 'https://fr.wikipedia.org/wiki/Probl%C3%A8me_de_Monty_Hall'
								}
							]}
						/>
						<Project 
							title='Slippi Stats Web'
							description="Une application web pour visualiser des données de parties de Super Smash Bros. Melee avec Slippi."
							tags={[
								'Jeu vidéo',
								'Smash',
								'Python',
								'Data'
							]}
							links={[
								{
									title: 'Démo',
									href: 'https://slippi.coukaratcha.fr'
								},
								{
									title: 'Projet',
									href: 'https://gitlab.com/Coukaratcha/slippi-stats-web'
								}
							]}
						/>
						<Project 
							title='Speedrun'
							description="Je pratique le speedrun depuis quelques années, plus particulièrement sur <em>Super Smash Bros. Melee</em>, <em>Prey</em> (2017) et <em>DEATHLOOP</em>. J'ai notamment participé à l'édition 2023 de <a href='https://blog.coukaratcha.fr/2023/04/03/jai-participe-a-speedons/'>Speedons</a> en tant que runner sur <em>Super Smash Bros. Melee</em>. Une chaine Youtube regroupe mes meilleurs temps."
							tags={[
								'Jeu vidéo',
								'Speedrun',
							]}
							links={[
								{
									title: 'Records',
									href: 'https://blog.coukaratcha.fr/speedrun-pbs/'
								},
								{
									title: 'Youtube',
									href: 'https://youtube.com/@Couka'
								},
							]}
						/>
						<Project 
							title='Broderie'
							description='Je réalise des broderies sur toile ou vêtements, le plus souvent en point de croix. Je publie mes réalisations finies sur mon blog dans une catégorie dédiée.'
							tags={[
								'DIY',
								'Artisannat'
							]}
							links={[
								{
									title: 'Projets',
									href: 'https://blog.coukaratcha.fr/sujets/broderie/'
								}
							]}
						/>
						<Project 
							title='Jeu de Rôle'
							description="Je pratique le jeu de rôle sur table, principalement sur le jeu <em>Donjons & Dragons</em> 5<sup>ème</sup> édition en tant que joueur ou maître de jeu. J'ai mené plusieurs parties, que ce soit sur des scénarios courts (<em>One Shot</em>) ou sur des campagnes de plusieurs mois. Je publie des articles sur mon blog à propos de ma pratique du jeu de rôle."
							tags={[
								'Acting',
								'Animation',
								'Écriture'
							]}
							links={[
								{
									title: 'Blog',
									href: 'https://blog.coukaratcha.fr/sujets/jdr/'
								}
							]}
						/>
					</div>
				</div>
			</section>
		)
	}
}

export default ProjectsList;